<template>
  <div class="msgDetail">
    <div class="title" :class="detailData.type=='求购'?'title1':(detailData.type=='出售'?'title2':'title3')">{{detailData.type}}
      <van-icon name="arrow-left" class="goBack" @click="goBack"/>
    </div>
    <div style="padding:0px 10px">
      <div class="projectBrief">
        <div>详情:</div>
        <div>{{detailData.introduction}}</div>
        <!--      <p>{{detailData.introduction}}</p>-->
      </div>

      <div class="contactWay">
        <div>联系方式:</div>
        <div>地址：{{detailData.address}}</div>
        <div>电话：{{detailData.phone}}</div>
        <div>附加信息：{{detailData.other_contacts}}</div>
        <!--      <p>{{detailData.introduction}}</p>-->
      </div>
      <van-button class="goPost" type="info"  @click="goPost">发布信息</van-button>
    </div>
  </div>
</template>

<script>

import router from "@/router";

export default {
  //import引入的组件需要注入到对象中才能使用",
  components: {},
  data() {
    //这里存放数据",
    return {
      detailData:{}
    };
  },
  //监听属性 类似于data概念",
  computed: {},
  //监控data中的数据变化",
  watch: {},
  //方法集合",
  methods: {
    goBack(){
      this.$router.push({path:'/'})
    },
    goPost(){
      window.localStorage.removeItem('token')
      if (window.localStorage.getItem('token') == null){
        window.location.href = 'http://maplays.net/mobile/api/weiXinLogin'
      }else {
        router.push('/my?hasLogin')
      }
    }
  },
  //生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {
  },

  //生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {
    this.detailData=this.$route.params.data
  },
  //生命周期 - 挂载之前",html模板未渲染
  beforeMount() {

  },

  //生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {

  },

  //生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {

  },
  //生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {

  },
  //生命周期 - 销毁之前",
  beforeDestroy() {

  },
  destroyed() {

  },
  //生命周期 - 销毁完成",
  //如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {

  },
}

</script>

<style>
.msgDetail{
}
.title{
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  color: white;
}
.projectBrief{
  padding: 20px;
  background: white;
  border-radius: 0.28rem;
}
.projectBrief div:nth-child(1){
  font-size: 0.45rem;
  font-weight: bold;
  border-left: 8px solid orange;
  padding-left: 5px;
}
.projectBrief div:nth-child(2){
  font-size: 0.4rem;
  font-weight: normal;
  margin-top: 30px;
  text-indent: 50px;
}

.contactWay{
  padding: 20px;
  background: white;
  border-radius: 0.28rem;
  margin-top: 20px;
}

.contactWay div:nth-child(1){
  font-size: 0.45rem;
  font-weight: bold;
  border-left: 8px solid orange;
  padding-left: 5px;
}

.contactWay div:nth-child(2){
  font-size: 0.4rem;

  font-weight: normal;
  margin-top: 30px;
  text-indent: 50px;
}
.contactWay div:nth-child(3){
  font-size: 0.4rem;
  font-weight: normal;
  margin-top: 20px;
  text-indent: 50px;
}
.contactWay div:nth-child(4){
  font-size: 0.4rem;
  font-weight: normal;
  margin-top: 20px;
  text-indent: 50px;
}
.goBack{
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  color: #fff;
}
.goPost{
  width: 6rem;
  height: 1.2rem;
  text-align: center;
  line-height: 1.2rem;
  color: white;
  font-size: 0.5rem;
  border-radius: 0.5rem;
  margin-top: 2rem;
  transform: translateX(30%);
}
.title1{
  background: rgb(255, 151, 106);
}
.title2{
background: #07c160;
}
.title3{
  background: #1989fa;
}
</style>

